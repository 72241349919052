<template>
   <section class="cont">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>SCRM</el-breadcrumb-item>
            <el-breadcrumb-item>数据中台</el-breadcrumb-item>
            <el-breadcrumb-item>成长值配置</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <el-row class="label">等级成长值设置</el-row>
         <el-row class="rule-box">
            <el-row class="title">成长值计算规则</el-row>
            <el-row class="rule-cont">
               <p class="p1">
                  <i class="el-icon-warning-outline"></i>
                  成长值是根据不同成长值策略计算的分值和其对应的分值比例计算出的综合分值。
                  <span>成长值等于各策略分值乘以分值比例的总和。</span>
               </p>
               <el-divider content-position="left"><span>当前成长值为：</span></el-divider>
               <p><span>成长值</span> = 0.3 * RFM值 + 0.4 * 消费激励值 + 0.3 * 会员活跃值</p>
            </el-row>
         </el-row>
         <el-row class="growth-list">
            <el-row class="title">成长值策略设置</el-row>
            <el-row class="growth-item" v-for="(item, index) of growData" :key="index">
               <el-col :span="5" class="list-left">
                  <img src="@/assets/growthVal-icon2.png" alt="">
                  <p>{{item.growType | filterGrowType}}</p>
               </el-col>
               <el-col :span="19" class="list-right">
                  <el-row class="list-title">简单介绍：</el-row>
                  <el-row class="list-text">{{item.remark}}</el-row>
                  <el-row class="list-conf">
                     <el-switch v-model="item.isEnable" active-value="YES" inactive-value="NO"  ></el-switch>
                     <!--                  <el-input class="width-140"  placeholder="输入分值比例" v-model="item.weight"></el-input>-->
                     <input type="number" class="width-70 m-left-10"
                            v-model="item.weight" oninput="if(value>100)value=100"
                            min="0"
                            onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"

                     />
                     <a class="m-right-10">%</a>
                     <a class="m-right-10" style="color: red">成长值占比  值为 1% - 100%</a>
                     <span @click="handleConf(item)">去设置</span>
                  </el-row>
               </el-col>
            </el-row>
         </el-row>
      </el-row>
   </section>
</template>

<script>

   import { urlObj } from '@/api/interface'
   import { mapState } from 'vuex'
   export default {
      data() {
         return{
            growData: [],
            value2: '',
            value3: '',
            weight_no_null: '成长值不能为空！'
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      mounted() {
         this.getGrowConf()
      },
      methods: {
         // 获取成长值列表
         getGrowConf(){
            const url = urlObj.growConfList
            const param = { hotelId: this.hotelInfo.id }
            this.$axios.get(url, param).then(res => {
               if (res.success){
                  let data = res.records
                  let growData = [{growType: 'RFM'}, {growType: 'CONSUME'}, {growType: 'ACTIVITY'}]
                  data.forEach(item => {
                     if (item.growType === 'RFM'){
                        growData[0] = item
                     }
                     if (item.growType === 'CONSUME'){
                        growData[1] = item
                     }
                     if (item.growType === 'ACTIVITY'){
                        growData[2] = item
                     }
                  })
                  this.growData = growData
               }
            })
         },
         // 去设置
         handleConf(item){
            let path
            if (item.growType === 'RFM') {
               path = '/RFM_conf'
            }
            if (item.growType === 'CONSUME'){
               path = '/consume_conf'
            }
            if (item.growType === 'ACTIVITY'){
               path = '/activity_conf'
            }
            if (!item.weight) {
               this.$alert(this.weight_no_null, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            this.$router.push({ path,
               query: { id: item.id, weight: item.weight, hotelId: item.hotelId,
                        isEnable: item.isEnable, paramCfg: item.paramCfg}
            })
         }
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.getGrowConf()
               }
            },
            deep: true
         }
      },
      filters: {
         filterGrowType(val){
            switch (val) {
               case 'RFM':
                  return val = 'RFM模型策略'
               case 'CONSUME':
                  return val = '消费激励策略'
               case 'ACTIVITY':
                  return val = '会员活跃策略'
            }
         }
      }
   }
</script>

<style lang="scss" scoped>
   .title {
      border-bottom: #e5e6e6 solid 1px; padding: 25px 0 10px 20px;
      font-size: 16px;
   }
   .crumbs-box{
      border-bottom: none;
   }
   .label{
      line-height: 50px; background-color: #e4f5fd!important;
      color: #3497fd; border: solid 1px #c7e2fd!important;
      padding-left: 20px!important; margin-top: 30px;
   }
   .rule-box{
      margin-top: 20px; color: #222222; box-shadow: 0 0 8px #DCE7F9;
      span{ color: #419ffc }
      .rule-cont{
         .p1{
            i{ font-size: 28px; margin-right: 6px; color: #fe0606 }
         }
         p{ padding: 20px 0 20px 20px; line-height: 26px;  }
      }
   }
   .growth-list{
      margin-top: 20px; color: #222222; padding: 30px 0 0 0;
      .title{ margin-bottom: 30px }
      .growth-item{
         padding: 0 30px 30px;
         .list-left{
            padding: 24px 0; text-align: center; font-size: 18px;
            color: #ffffff; background: linear-gradient(to right, #0070fc , #1690fd);
            border-radius: 8px;
            img { width: 76px; margin-bottom: 10px }
         }
         .list-right{
            padding: 20px 40px 0 40px;
            .list-title{ font-size: 18px; margin-bottom: 10px; font-weight: 600 }
            .list-text{ line-height: 30px; margin-bottom: 10px }
         }
         .list-conf{
            .el-input{ margin: 0 20px }
            span{ color: #419ffc; text-decoration: underline; cursor: pointer }
         }
      }
   }
</style>
